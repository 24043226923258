import React, { Component } from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import Logo from '../../images/club-event/logo.png'
import GoogleMapReact from 'google-map-react'
import Button from '../form/buttons'
import IconShare from '../../images/ic_share.svg'
import Carousel, { Modal, ModalGateway } from 'react-images'
import ModalHandler from '../../utils/modal'

const EventContainer = styled.div`
  margin-top: 180px;
  font-family: RSU;
  ${media.lessThan('medium')`
    margin-top: 90px;
  `}
`

const LogoContainer = styled.div`
  width: 200px;
  height: 200px;
  top: -100px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;

  ${media.lessThan('medium')`
    width: 100px;
    height: 100px;
    top: -50px;
  `}
`

const ColumnHeader = styled.div`
  font-size: 24px;
  color: #3f3131;
  position: relative;
  display: flex;
  align-items: center;
  position: relative;
  display: flex;
  align-items: center;

  hr {
    height: 24px;
    width: 3px;
    background: #c14c40;
  }

  div {
    color: #3f3131;
    margin-left: 16px;
  }

  ${media.lessThan('medium')`
    font-size: 18px;
  `}
`

const EvenrHeaderContainer = styled.div`
  text-align: center;
  margin-top: 80px;
  margin-bottom: 24px;
  ${media.lessThan('medium')`
    margin-top: 40px;
  `}
`

const EventHeader = styled.h3`
  opacity: 0.87;
  font-size: 34px;
  color: #463838;
  letter-spacing: 0.25px;
  margin-bottom: 0.4em;
  ${media.lessThan('medium')`
    font-size: 20px;
  `}
`

const EvenrSubHeader = styled.h4`
  opacity: 0.6;
  font-size: 20px;
  color: #3f3131;
  letter-spacing: 0.25px;
  ${media.lessThan('medium')`
    font-size: 14px;
  `}
`

const EventDatetime = styled.div`
  opacity: 0.87;
  font-size: 16px;
  color: #3f3131;
  letter-spacing: 0.5px;
  line-height: 28px;
  span {
    margin-left: 12px;
    margin-right: 24px;
  }
  ${media.lessThan('medium')`
    font-size: 14px;
  `}
`

const Line = styled.hr`
  border: 0;
  height: 1px;
  background: #f0f0f1;
  margin: 20px 0;
`

const Section = styled.div`
  margin-bottom: 32px;
`

const EventTextContainer = styled.div`
  display: flex;
  margin-bottom: 12px;

  div:first-child {
    width: 160px;
    min-width: 160px;
    ${media.lessThan('medium')`
      width: 100px;
      min-width: 100px;
    `}
  }
`

const EventTextTicketContainer = styled(EventTextContainer)`
  div:first-child {
    width: 160px;
    min-width: 160px;
    ${media.lessThan('medium')`
      width: 140px;
      min-width: 140px;
    `}
  }
`

const EventText = styled.div`
  opacity: 0.87;
  font-size: 20px;
  color: #3f3131;
  letter-spacing: 0.25px;
  ${media.lessThan('medium')`
    font-size: 16px;
  `}
`

const ImgContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  div:not(:last-child) {
    margin-right: 10px;
  }
`

const ImgItem = styled.div`
  width: 200px;
  height: auto;
  background: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px;
`

const SeeMore = styled.button`
  background: rgba(137, 42, 36, 0.1);
  border: 1px solid rgba(137, 42, 36, 0.28);
  border-radius: 2px;
  width: 100%;
  height: 36px;
  cursor: pointer;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  i {
    margin-right: 10px;
  }
`

const PlaceText = styled.div`
  opacity: 0.87;
  font-size: 20px;
  color: #3f3131;
  letter-spacing: 0.25px;
  margin-top: 12px;
`

const EventButton = styled.button`
  opacity: 0.87;
  font-size: 16px;
  color: #892a24;
  letter-spacing: 0.5px;
  text-align: right;
  line-height: 28px;
  background: #ffffff;

  i,
  img {
    margin-right: 12px;
  }
`

const JoinEventContainer = styled.div`
  /* position: fixed;  */ /* TODO:  */
  width: 100%;
  left: 0;
  background: #ffffff;
  bottom: 0;
  z-index: 1000;
`

const MapAndPlaceContainer = styled.div`
  display: flex;
  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`

const MapContainer = styled.div`
  flex: 1;
  margin-right: 10px;
  ${media.lessThan('medium')`
    margin-right: 0;
    margin-bottom: 32px;
  `}
`

const PlaceContainer = styled.div`
  flex: 1;
  margin-left: 10px;
  ${media.lessThan('medium')`
    margin-left: 0;
    margin-bottom: 32px;
  `}
`

export default class ClubEvents extends Component {
  state = { modalIsOpen: false }
  toggleModal = () => {
    this.setState(state => ({ modalIsOpen: !state.modalIsOpen }))
  }

  render() {
    const { modalIsOpen } = this.state
    console.log(this.props)
    return (
      <EventContainer>
        <div style={{ position: 'relative' }}>
          <LogoContainer>
            <img src={Logo} alt="" />
          </LogoContainer>
        </div>
        <div className="club__card">
          <EvenrHeaderContainer>
            <EventHeader>Chongkho Night 2020</EventHeader>
            <EvenrSubHeader>ถนนเส้นนี้ ... ที่พี่เคยเดิน</EvenrSubHeader>
            <EventDatetime>
              <i className="far fa-calendar" />
              <span>January 20,2020</span>
              <i className="far fa-clock" />
              <span>03:00 PM</span>
            </EventDatetime>
          </EvenrHeaderContainer>
          <Section>
            <ColumnHeader>
              <hr />
              <div>{this.props.locale['ACTIVITY_SCHEDULE']}</div>
            </ColumnHeader>
            <Line />
            <EventTextContainer>
              <EventText>15.00 น</EventText>
              <EventText>เปิดตลาด</EventText>
            </EventTextContainer>
            <EventTextContainer>
              <EventText>19.00 น</EventText>
              <EventText>พิธีเปิด</EventText>
            </EventTextContainer>
            <EventTextContainer>
              <EventText>19.30 น</EventText>
              <EventText>การแสดงจากชุมนุมและชมรม</EventText>
            </EventTextContainer>
            <EventTextContainer>
              <EventText>20.20 น</EventText>
              <EventText>ประมูลรูปภาพ</EventText>
            </EventTextContainer>
            <EventTextContainer>
              <EventText>20.50 น</EventText>
              <EventText>ถ่ายรูปหมู่ และมี After Party</EventText>
            </EventTextContainer>
          </Section>
          <Section>
            <ColumnHeader>
              <hr />
              <div>{this.props.locale['TICKET_PRICE']}</div>
            </ColumnHeader>
            <Line />
            <EventTextTicketContainer>
              <EventText>บัตรเดี่ยว</EventText>
              <EventText>700 บาท</EventText>
            </EventTextTicketContainer>
            <EventTextTicketContainer>
              <EventText>บัตรกลุ่ม (8ที่นั่ง)</EventText>
              <EventText>5,600 บาท</EventText>
            </EventTextTicketContainer>
          </Section>
          <Section>
            <ColumnHeader>
              <hr />
              <div>{this.props.locale['PHOTO_GALLERY']}</div>
            </ColumnHeader>
            <Line />
            <ModalGateway>
              {/* http://jossmac.github.io/react-images/#/styles */}
              {modalIsOpen ? (
                <Modal
                  onClose={this.toggleModal}
                  styles={{
                    blanket: (base, state) => ({ ...base, zIndex: 1100 }),
                    positioner: (base, state) => ({ ...base, zIndex: 1110 }),
                    dialog: (base, state) => ({ ...base, zIndex: 1120 }),
                  }}
                >
                  <Carousel
                    views={[
                      { source: 'https://via.placeholder.com/200.png' },
                      { source: 'https://via.placeholder.com/200.png' },
                      { source: 'https://via.placeholder.com/200.png' },
                      { source: 'https://via.placeholder.com/200.png' },
                    ]}
                  />
                </Modal>
              ) : null}
            </ModalGateway>
            <ImgContainer>
              <ImgItem>
                <img
                  onClick={() => this.setState({ modalIsOpen: true })}
                  src="https://via.placeholder.com/200.png"
                  alt=""
                />
              </ImgItem>
              <ImgItem>
                <img src="https://via.placeholder.com/200.png" alt="" />
              </ImgItem>
              <ImgItem>
                <img src="https://via.placeholder.com/200.png" alt="" />
              </ImgItem>
              <ImgItem>
                <img src="https://via.placeholder.com/200.png" alt="" />
              </ImgItem>
            </ImgContainer>
            <SeeMore>
              <span>
                <i className="fas fa-chevron-down" /> SEE MORE 6 PHOTOS
              </span>
            </SeeMore>
          </Section>
          <Section>
            <MapAndPlaceContainer>
              <MapContainer>
                <ColumnHeader>
                  <hr />
                  <div>{this.props.locale['MAP']}</div>
                </ColumnHeader>
                <Line />
                <div style={{ height: '320px', width: '100%' }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: process.env.GATSBY_GOOGLE_MAP_KEY,
                      // key: 'AIzaSyDysrc0u-4TlWYeTZk94P_d2aWshVe54Do',
                    }}
                    defaultCenter={{
                      lat: 13.6510248,
                      lng: 100.4953802,
                    }}
                    defaultZoom={16}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => {
                      console.log('map:', map)
                      let marker = new maps.Marker({
                        position: {
                          lat: 13.6510248,
                          lng: 100.4953802,
                        },
                        map,
                        // title: 'Hello World!',
                        // label: {
                        //   text: 'Hello World!',
                        // },
                      })
                    }}
                    options={{
                      streetViewControl: true,
                      disableDefaultUI: false,
                      mapTypeControl: true,
                      styles: [
                        {
                          featureType: 'poi',
                          elementType: 'labels',
                          stylers: [{ visibility: 'on' }],
                        },
                      ],
                    }}
                  />
                </div>
              </MapContainer>
              <PlaceContainer>
                <ColumnHeader>
                  <hr />
                  <div>{this.props.locale['PLACE']}</div>
                </ColumnHeader>
                <Line />
                <div>
                  <img src="https://via.placeholder.com/400x250.png" alt="" />
                  <PlaceText>
                    Thanon Chalong Krung, Lat Krabang, Bangkok 10520
                  </PlaceText>
                </div>
              </PlaceContainer>
            </MapAndPlaceContainer>
          </Section>
          <JoinEventContainer className="flex justify-center">
            <EventButton>
              <img src={IconShare} alt="" />{' '}
              {this.props.locale['SHARE_ACTIVITY']}
            </EventButton>
            <Button.Button7
              style={{
                padding: 0,
                width: 200,
                height: 36,
                margin: '16px 24px',
              }}
              onClick={() => ModalHandler.openModal('modalSignUp')}
            >
              {this.props.locale['JOIN_THIS_EVENT']}
            </Button.Button7>
            <EventButton>
              <i className="far fa-calendar" />{' '}
              {this.props.locale['ADD_TO_CALENDAR']}
            </EventButton>
          </JoinEventContainer>
        </div>
      </EventContainer>
    )
  }
}
